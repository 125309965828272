import React from 'react'

import styles from './licensing.module.css'

import { playlists, text } from '../../../content/data/licensing.json'
import { Playlist } from './Playlist'
import { Markdown } from '../markdown/Markdown'

export const Licensing: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <Markdown content={text} />
      </div>
      {playlists ? (
        <ul className={styles.playlists}>
          {playlists.map(playlist => (
            <li key={playlist.id} className={styles.playlist}>
              <Playlist id={playlist.id} title={playlist.playlist_title} />
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}
