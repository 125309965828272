import React, { useEffect, useContext } from 'react'

import Layout from '../components/layout/Layout'
import { SEO } from '../components/SEO'

import { myContext } from '../provider'
import { Licensing } from '../components/licensing/Licensing'

const LicensingPage: React.FunctionComponent = () => {
  const context = useContext(myContext)
  useEffect(() => {
    context.updateActivePage('licensing')
  }, [context])

  return (
    <>
      <Layout>
        <SEO title={'Licensing'} />
        <Licensing />
      </Layout>
    </>
  )
}

export default LicensingPage
