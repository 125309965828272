import React from 'react'

interface Props {
  id: string
  title: string
}

export const Playlist: React.FC<Props> = ({ id, title }) => {
  const src =
    'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/' +
    id +
    '&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true'
  return (
    <>
      <h3>{title}</h3>
      <iframe
        // className={styles.playlist}
        title={title}
        width="100%"
        height="600"
        scrolling="yes"
        allow="autoplay"
        src={src}
      ></iframe>
    </>
  )
}
